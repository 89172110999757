import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'
import { type VariantProps } from 'class-variance-authority'
import * as React from 'react'
import { cn } from '@/lib/utils'
import { toggleVariants } from '@/components/ui/toggle'
import { useTranslate, TranslationKey } from '@/i18n'

const ToggleGroup = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root> &
    VariantProps<typeof toggleVariants>
>(({ className, children, ...props }, ref) => (
  <ToggleGroupPrimitive.Root
    ref={ref}
    className={cn('flex items-center justify-center gap-4', className)}
    {...props}
  >
    {children}
  </ToggleGroupPrimitive.Root>
))
ToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName

const ToggleGroupItem = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item> &
    VariantProps<typeof toggleVariants> & { unstyled?: boolean }
>(({ className, children, unstyled, ...props }, ref) => (
  <ToggleGroupPrimitive.Item
    ref={ref}
    className={unstyled ? className : toggleVariants({ className })}
    tabIndex={0}
    {...props}
  >
    {children}
  </ToggleGroupPrimitive.Item>
))
ToggleGroupItem.displayName = ToggleGroupPrimitive.Item.displayName

const ToggleGroupItemFromKey = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Item>,
  Omit<React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item>, 'value' | 'children'> &
    VariantProps<typeof toggleVariants> & { translationKey: TranslationKey }
>(({ translationKey, className, ...props }, ref) => {
  const { t } = useTranslate()
  const value = t(translationKey)

  return (
    <ToggleGroupItem ref={ref} value={value} {...props}>
      {value}
    </ToggleGroupItem>
  )
})
ToggleGroupItem.displayName = 'ToggleGroupItemFromKey'

export { ToggleGroup, ToggleGroupItem, ToggleGroupItemFromKey }
