import { AuthStep, useAuth } from '@/app/_auth'
import {
  AuthStepTemplate,
  consentFormSchema,
  ConsentFormSchema,
  useRegistrationForm,
  useSaveRegistrationForm,
} from '@/app/_auth/steps'
import {
  Button,
  Checkbox,
  CheckboxLabel,
  Form,
  FormControl,
  FormField,
  FormItem,
  toast,
} from '@/components/ui'
import { T, useTranslate } from '@/i18n'
import { GA_EVENTS } from '@/lib'
import { zodResolver } from '@hookform/resolvers/zod'
import ReactGA from 'react-ga4'
import { useForm } from 'react-hook-form'

export function RegisterConsentStep() {
  const { t } = useTranslate()
  const { setStep } = useAuth()
  const { orgData, purposeData, consentData, setConsentData } = useRegistrationForm()
  const { isPending, mutateAsync } = useSaveRegistrationForm()

  const form = useForm<ConsentFormSchema>({
    disabled: isPending,
    resolver: zodResolver(consentFormSchema),
    ...(consentData && { values: consentData }),
    defaultValues: {
      allowMarketing: true,
      allowData: true,
    },
  })

  const submit = (data: ConsentFormSchema) => {
    setConsentData(data)

    if (!orgData) {
      setStep(AuthStep.RegisterOrg)
      toast.error(t('auth.steps.preferences.errorToast'))
      return
    }

    if (!purposeData) {
      setStep(AuthStep.RegisterPurpose)
      toast.error(t('auth.steps.preferences.errorToast'))
      return
    }

    ReactGA.event({
      action: GA_EVENTS.ACTION.BTN,
      category: GA_EVENTS.CATEGORY.ACC,
      label: 'submit_consent_settings_registration_form',
    })

    mutateAsync({ ...data, ...orgData, ...purposeData })
      .then(() => setStep(AuthStep.GetEmergenceUser))
      .catch((e) => {
        console.error(e)
        toast.error('Failed to submit registration')
      })
  }

  const back = () => {
    setConsentData(form.getValues())
    setStep(AuthStep.RegisterPurpose)
  }

  return (
    <AuthStepTemplate>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(submit)} className='flex flex-col gap-8'>
          <AuthStepTemplate.Header className='gap-1'>
            <AuthStepTemplate.H2 keyName='auth.steps.preferences.title' />
            <AuthStepTemplate.RequiredFieldsMessage />
          </AuthStepTemplate.Header>
          <div className='flex w-full flex-col gap-3'>
            <FormField
              control={form.control}
              name='allowData'
              render={({ field: { onChange, value, ...field } }) => (
                <FormItem className='flex gap-3'>
                  <FormControl className='mt-2'>
                    <Checkbox autoFocus checked={value} onCheckedChange={onChange} {...field} />
                  </FormControl>
                  <CheckboxLabel>
                    <T keyName='auth.steps.preferences.allowData' />
                  </CheckboxLabel>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='allowMarketing'
              render={({ field: { onChange, value, ...field } }) => (
                <FormItem className='flex gap-3'>
                  <FormControl className='mt-2'>
                    <Checkbox checked={value} onCheckedChange={onChange} {...field} />
                  </FormControl>
                  <CheckboxLabel>
                    <T keyName='auth.steps.preferences.allowFeedbackRequests' />
                  </CheckboxLabel>
                </FormItem>
              )}
            />
            <div className='mx-auto max-w-xs py-5 text-center text-sm font-medium'>
              <T
                keyName='auth.steps.preferences.terms'
                params={{
                  t: (
                    <a
                      className='text-link underline'
                      href={import.meta.env.VITE_TERMS_OF_SERVICE_URL}
                      target='_blank'
                      title={t('navigation.privacyPolicy.a11y')}
                    />
                  ),
                  p: (
                    <a
                      className='text-link underline'
                      href={import.meta.env.VITE_PRIVACY_POLICY_URL}
                      target='_blank'
                      title={t('navigation.terms.a11y')}
                    />
                  ),
                }}
              />
            </div>
            <AuthStepTemplate.ButtonRow>
              <Button
                onClick={back}
                variant='square-outline'
                className='w-full'
                size='sm'
                disabled={isPending}
              >
                <T keyName='auth.steps.common.buttons.back' />
              </Button>
              <Button
                loading={isPending}
                variant='square'
                size='sm'
                className='w-full'
                type='submit'
              >
                <T keyName='auth.steps.common.buttons.createAccount' />
              </Button>
            </AuthStepTemplate.ButtonRow>
          </div>
        </form>
      </Form>
      <AuthStepTemplate.Footer />
    </AuthStepTemplate>
  )
}
