export const WorkspaceIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask
      id='mask0_4264_220'
      style={{ maskType: 'alpha' }}
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='24'
      height='24'
    >
      <rect width='24' height='24' fill='#D9D9D9' />
    </mask>
    <g mask='url(#mask0_4264_220)'>
      <path
        d='M6 17.259V6.74104C6 5.96925 6.83721 5.48837 7.50387 5.87726L16.5192 11.1362C17.1807 11.5221 17.1807 12.4779 16.5192 12.8638L7.50387 18.1227C6.83721 18.5116 6 18.0308 6 17.259Z'
        stroke='#6B6673'
        strokeLinejoin='round'
      />
    </g>
  </svg>
)
