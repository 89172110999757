export const GA_EVENTS = {
  CATEGORY: {
    NAV: 'navigation',
    ACC: 'account_settings',
    EMAIL: 'email_form',
  },
  ACTION: {
    BTN: 'button_click',
  },
} as const
