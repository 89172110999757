import { SideNavigation } from '@/app/_common'
import { AppProduct, DEFAULT_APP_PRODUCT, useAppProduct } from '@/app/app-product-context'
import { NotFoundPage } from '@/app/not-found'
import {
  createRootRoute,
  Outlet,
  retainSearchParams,
  useNavigate,
  useSearch,
} from '@tanstack/react-router'
import React from 'react'

type SearchParams = {
  product?: AppProduct
}

export const Route = createRootRoute({
  notFoundComponent: NotFoundPage,
  validateSearch: () => ({}) as SearchParams,
  search: {
    middlewares: [retainSearchParams(['product'])],
  },
  component: RootRoute,
})

function RootRoute() {
  const { setAppProduct } = useAppProduct()
  const { product = DEFAULT_APP_PRODUCT } = useSearch({ strict: false })
  const navigate = useNavigate()

  React.useEffect(() => {
    if (Object.values(AppProduct).includes(product)) {
      setAppProduct(product)
    }

    if (product === AppProduct.Easy) {
      navigate({ to: '/account', search: { product } })
    }
  }, [])

  return (
    <>
      <SideNavigation />
      <Outlet />
    </>
  )
}
