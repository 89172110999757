import { cn } from '@/lib/utils'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import React, { PropsWithChildren, useRef } from 'react'

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, children, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'text-primary-foreground animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 overflow-hidden rounded-md bg-primary px-3 py-2 font-neue-machina text-xs tracking-wide text-white',
      className,
    )}
    {...props}
  >
    {children}
    <TooltipPrimitive.Arrow className='fill-primary' />
  </TooltipPrimitive.Content>
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

export { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger }

interface TooltipProps {
  className?: string
  content: React.ReactNode
  side?: 'top' | 'right' | 'bottom' | 'left'
}

export const TooltipSimple = ({
  children,
  content,
  side = 'top',
  className,
}: PropsWithChildren<TooltipProps>) => {
  const triggerRef = useRef(null)

  return (
    <div className='flex justify-center'>
      <TooltipPrimitive.Root>
        <TooltipPrimitive.Trigger
          disabled
          asChild
          onClick={(event) => event.preventDefault()}
          ref={triggerRef}
        >
          {children}
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content
            className={cn(
              'z-50 rounded-md bg-primary px-3 py-2 font-neue-machina text-xs text-white',
              className,
            )}
            onPointerDownOutside={(event) => {
              event.preventDefault()
            }}
            side={side}
            sideOffset={5}
          >
            {content}
            <TooltipPrimitive.Arrow className='fill-primary' />
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </div>
  )
}
