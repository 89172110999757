export const HomeIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='path-1-inside-1_2819_15751' fill='white'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0008 4L21.0323 12.1284C21.3729 12.4349 21.156 13 20.6978 13H18.0008V19C18.0008 19.5523 17.5531 20 17.0008 20H13.0009V16C13.0009 15.4477 12.5531 15 12.0009 15C11.4486 15 11.0009 15.4477 11.0009 16V20H7.00083C6.44855 20 6.00083 19.5523 6.00083 19V13H3.30374C2.8455 13 2.62865 12.4349 2.96926 12.1284L12.0008 4Z'
      />
    </mask>
    <path
      d='M21.0323 12.1284L20.3633 12.8716V12.8716L21.0323 12.1284ZM12.0008 4L12.6697 3.25671L12.0008 2.65464L11.3318 3.25671L12.0008 4ZM18.0008 13V12H17.0008V13H18.0008ZM13.0009 20H12.0009V21H13.0009V20ZM11.0009 20V21H12.0009V20H11.0009ZM6.00083 13H7.00083V12H6.00083V13ZM2.96926 12.1284L3.63822 12.8716L3.63822 12.8716L2.96926 12.1284ZM21.7012 11.3851L12.6697 3.25671L11.3318 4.74329L20.3633 12.8716L21.7012 11.3851ZM20.6978 14C22.0725 14 22.723 12.3047 21.7012 11.3851L20.3633 12.8716C20.0227 12.5651 20.2395 12 20.6978 12V14ZM18.0008 14H20.6978V12H18.0008V14ZM19.0008 19V13H17.0008V19H19.0008ZM17.0008 21C18.1054 21 19.0008 20.1046 19.0008 19H17.0008V21ZM13.0009 21H17.0008V19H13.0009V21ZM12.0009 16V20H14.0009V16H12.0009ZM12.0009 16H14.0009C14.0009 14.8954 13.1054 14 12.0009 14V16ZM12.0009 16V14C10.8963 14 10.0009 14.8954 10.0009 16H12.0009ZM12.0009 20V16H10.0009V20H12.0009ZM7.00083 21H11.0009V19H7.00083V21ZM5.00083 19C5.00083 20.1046 5.89626 21 7.00083 21V19H7.00083H5.00083ZM5.00083 13V19H7.00083V13H5.00083ZM3.30374 14H6.00083V12H3.30374V14ZM2.30029 11.3851C1.27847 12.3047 1.92901 14 3.30374 14V12C3.76198 12 3.97883 12.5651 3.63822 12.8716L2.30029 11.3851ZM11.3318 3.25671L2.30029 11.3851L3.63822 12.8716L12.6697 4.74329L11.3318 3.25671Z'
      fill='#6B6673'
      mask='url(#path-1-inside-1_2819_15751)'
    />
  </svg>
)
