import { useAuth } from '@/app/_auth'
import { cn } from '@/lib/utils'
import * as AvatarPrimitive from '@radix-ui/react-avatar'
import { User2Icon } from 'lucide-react'
import * as React from 'react'

const AvatarRoot = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn('relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full', className)}
    {...props}
  />
))
AvatarRoot.displayName = AvatarPrimitive.Root.displayName

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn('aspect-square h-full w-full', className)}
    {...props}
  />
))
AvatarImage.displayName = AvatarPrimitive.Image.displayName

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      'flex h-full w-full items-center justify-center rounded-full bg-grey-600 font-neue-machina font-normal uppercase text-white',
      className,
    )}
    {...props}
  />
))
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName

export const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root> & { size?: 'sm' | 'md' }
>(({ size = 'md', className, ...props }, ref) => {
  const { user } = useAuth()

  return (
    <AvatarRoot
      ref={ref}
      className={cn({ 'h-5 w-5': size === 'sm', 'h-10 w-10': size === 'md' }, className)}
      {...props}
    >
      <AvatarImage alt='User profile avatar' src={user?.photoURL} />
      <AvatarFallback
        className={cn({
          'pt-[3px]': user?.firstName,
          'text-xs': size === 'sm',
          'text-xl': size === 'md',
        })}
      >
        {user?.firstName ? user.firstName[0] : <User2Icon />}
      </AvatarFallback>
    </AvatarRoot>
  )
})
Avatar.displayName = 'Avatar'
