import { cva, VariantProps } from 'class-variance-authority'
import * as React from 'react'
import { inputVariants } from './input'

export const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & TextAreaVariants
>(({ className, variant, ...props }, ref) => {
  return (
    <textarea
      tabIndex={0}
      className={textAreaVariants({ variant, className })}
      ref={ref}
      {...props}
    />
  )
})
Textarea.displayName = 'Textarea'

type TextAreaVariants = VariantProps<typeof textAreaVariants>
const textAreaVariants = cva('', {
  variants: {
    variant: {
      input: [inputVariants({ height: 'auto', text: 'md' }), 'px-3 py-2'],
      prompt:
        'bg-[rgba(238,238,239,0.5)] p-10 text-xl xl:text-[32px] xl:leading-[40px] tracking-wide w-full font-neue-machina outline-none',
    },
  },
  defaultVariants: {
    variant: 'input',
  },
})
