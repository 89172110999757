export const EmergenceIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.0183 24C17.5525 24 20.8767 20.347 20.8767 19.5251C20.8767 18.4475 14.8128 16.0731 10.8493 16.0731C6.79453 16.0731 1.78996 17.2785 1.78996 18.0091C1.78996 19.3973 6.15526 24 12.0183 24ZM1.29681 14.9041C6.137 14.9041 12.9315 13.7534 12.9315 12.4566C12.9315 10.831 4.91325 4.91324 2.50229 4.91324C1.38813 4.91324 0 8.32877 0 11.8539C0 13.9909 0.456622 14.9041 1.29681 14.9041ZM10.0457 6.46575C10.3196 6.46575 10.5206 6.00913 10.5206 4.73059C10.5206 2.72146 9.91782 0.219178 9.33335 0.219178C8.03654 0.219178 4.96804 1.60731 4.96804 2.6484C4.96804 3.65297 8.8402 6.46575 10.0457 6.46575ZM22.1553 17.6986C22.9407 17.6986 24 14.8858 24 12.3105C24 11.105 23.6895 10.8493 21.9726 10.8493C17.8813 10.8493 15.1233 11.5434 15.1233 12.1096C15.1233 13.2603 20.7854 17.6986 22.1553 17.6986ZM15.5617 9.51598C19.9818 9.51598 22.9589 8.54795 22.9589 7.52511C22.9589 5.15068 18.1187 0 13.4795 0C12.6028 0 12.2192 1.27854 12.2192 4.16438C12.2192 8.60274 12.7854 9.51598 15.5617 9.51598Z'
      fill='#252539'
    />
  </svg>
)
