import { useAuthError } from '@/app/_auth'
import { AuthStepTemplate } from '@/app/_auth/steps'

export function LinkProviderStep() {
  const { providerName, verifiedProvider, email } = useAuthError()

  return (
    <AuthStepTemplate>
      <AuthStepTemplate.Header>
        <AuthStepTemplate.H1 keyName='auth.steps.linkProvider.title' />
        <AuthStepTemplate.H2 keyName='auth.steps.login' />
        <AuthStepTemplate.P
          keyName='auth.steps.linkProvider.description'
          params={{ email, providerName, b: <span className='font-medium' /> }}
        />
      </AuthStepTemplate.Header>
      <AuthStepTemplate.LoginButtons providers={verifiedProvider} />
      <AuthStepTemplate.Footer />
    </AuthStepTemplate>
  )
}
