import { cva, VariantProps } from 'class-variance-authority'
import { XIcon } from 'lucide-react'
import { toast as baseToast, Toaster as Sonner } from 'sonner'

type ToasterProps = React.ComponentProps<typeof Sonner>

export const Toaster = (props: ToasterProps) => (
  <Sonner
    className='toaster group'
    toastOptions={{
      classNames: {
        toast:
          'group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
        description: 'group-[.toast]:text-muted-foreground',
        actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
        cancelButton: 'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
      },
    }}
    {...props}
  />
)

export const toast = {
  error: (message: React.ReactNode) => customToast({ variant: 'error', message }),
  success: (message: React.ReactNode) => customToast({ variant: 'success', message }),
}

type ToastVariants = VariantProps<typeof toastVariants>
const toastVariants = cva(
  'flex w-[360px] items-center text-sm font-[450] justify-between rounded border-l-8 px-4 py-3 shadow-[0_-3px_12px_0_rgba(0,0,0,.1)]',
  {
    variants: {
      variant: {
        error: 'border-l-error-accent bg-error',
        success: 'border-l-success-accent bg-success',
      },
    },
  },
)

function customToast({ variant, message }: ToastVariants & { message: React.ReactNode }) {
  return baseToast.custom((t) => (
    <div className={toastVariants({ variant })}>
      {message}
      <button tabIndex={0} onClick={() => baseToast.dismiss(t)}>
        <XIcon className='w-4 text-[#827C8C]' />
      </button>
    </div>
  ))
}
