import { z } from 'zod'

// 👇 consent form

export const consentFormSchema = z.object({
  allowData: z.boolean(),
  allowMarketing: z.boolean(),
})
export type ConsentFormSchema = z.infer<typeof consentFormSchema>

// 👇 purpose form

export const MAX_PURPOSE_CHARS = 255
export const purposeFormSchema = z.object({
  purpose: z.string().trim().min(1).max(MAX_PURPOSE_CHARS),
})
export type PurposeFormSchema = z.infer<typeof purposeFormSchema>

// 👇 org form

export const MAX_ORG_CHARS = 200
export const orgFormSchema = z.object({
  fullName: z.string().trim().min(1).max(MAX_ORG_CHARS),
  orgName: z.string().trim().min(1).max(MAX_ORG_CHARS),
  role: z.string().trim().min(1).max(MAX_ORG_CHARS),
  orgSize: z.string().trim().min(1).max(MAX_ORG_CHARS),
  industry: z.string().trim().min(1).max(MAX_ORG_CHARS),
  otherIndustry: z.string().trim().max(MAX_ORG_CHARS),
})
export type OrgFormSchema = z.infer<typeof orgFormSchema>

// 👇 registration form

export const registrationFormSchema = orgFormSchema.and(consentFormSchema).and(purposeFormSchema)
export type RegistrationFormSchema = z.infer<typeof registrationFormSchema>
