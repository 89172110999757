import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { AppRoot } from './app/app-root'
import './styles/global.css'

const rootElement = document.getElementById('root')

if (rootElement && !rootElement.innerHTML) {
  ReactDOM.createRoot(rootElement).render(
    <StrictMode>
      <AppRoot />
    </StrictMode>,
  )
}
