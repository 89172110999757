import { cn } from '@/lib/utils'
import { forwardRef } from 'react'
import { FieldError } from 'react-hook-form'

type TextFieldProps = {
  label: string
  placeholder: string
  error?: FieldError
  errorMessage?: string
  isRequired?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ label, placeholder, error, errorMessage, isRequired = false, ...props }, ref) => {
    return (
      <label className='flex flex-col gap-0.5 font-neue-machina text-sm font-light'>
        {label}
        {isRequired && '*'}
        <input
          type='text'
          className={cn(
            'rounded bg-content px-3 py-2 font-circular-xx text-base font-light text-grey-800 outline-none hover:bg-grey-200 focus:shadow-input-focus',
            error ? 'shadow-input-error focus:shadow-input-error' : 'shadow-input',
          )}
          placeholder={placeholder}
          ref={ref}
          {...props}
        />
        <div className='h-6'>
          {error && errorMessage && (
            <span className='text-error-accent mt-0.5 text-sm font-light'>{errorMessage}</span>
          )}
        </div>
      </label>
    )
  },
)
TextField.displayName = 'TextField'

export { TextField }
