import { auth, AuthStep, useAuth } from '@/app/_auth'
import {
  AuthStepTemplate,
  MAX_ORG_CHARS,
  orgFormSchema,
  OrgFormSchema,
  useRegistrationForm,
} from '@/app/_auth/steps'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormLabelAsterisk,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItemFromKey,
  SelectTrigger,
  SelectValue,
} from '@/components/ui'
import { T, TranslationKey, useTranslate } from '@/i18n'
import { GA_EVENTS } from '@/lib'
import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import ReactGA from 'react-ga4'
import { useForm } from 'react-hook-form'

export function RegisterOrgStep() {
  const { t } = useTranslate()
  const { setStep } = useAuth()
  const { setOrgData, orgData } = useRegistrationForm()

  const form = useForm<OrgFormSchema>({
    mode: 'onChange',
    resolver: zodResolver(orgFormSchema),
    ...(orgData && { values: orgData }),
    defaultValues: {
      fullName: '',
      orgName: '',
      role: '',
      orgSize: '',
      industry: '',
      otherIndustry: '',
    },
  })

  const isOtherSelected = (selectedValue?: string) => t(OTHER_INDUSTRY_OPTION) === selectedValue
  const isMounted = React.useRef(false)
  const [showOther, setShowOther] = React.useState(() => isOtherSelected(orgData?.industry))

  const isSubmitDisabled = !form.formState.isValid || (showOther && !form.watch('otherIndustry'))

  const submit = (data: OrgFormSchema) => {
    if (showOther && !data.otherIndustry) {
      return form.setError('otherIndustry', {
        message: t('auth.steps.orgForm.industry.other.validationError'),
      })
    }

    ReactGA.event({
      action: GA_EVENTS.ACTION.BTN,
      category: GA_EVENTS.CATEGORY.ACC,
      label: 'submit_organization_registration_form',
    })

    setOrgData(data)
    setStep(AuthStep.RegisterPurpose)
  }

  const back = () => {
    setOrgData(form.getValues())
    setStep(AuthStep.Create)
  }

  React.useEffect(() => {
    setTimeout(() => (isMounted.current = true))

    if (form.getValues('fullName')) return

    if (auth.currentUser?.displayName) {
      form.setValue('fullName', auth.currentUser.displayName)
    }
  }, [])

  React.useEffect(() => {
    if (!isMounted.current) return

    if (showOther) setTimeout(() => form.setFocus('otherIndustry'))
    else form.setValue('otherIndustry', '')
  }, [showOther])

  return (
    <AuthStepTemplate>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(submit)} className='flex flex-col gap-8'>
          <AuthStepTemplate.Header className='gap-1'>
            <AuthStepTemplate.H2 keyName='auth.steps.orgForm.title' />
            <AuthStepTemplate.RequiredFieldsMessage />
          </AuthStepTemplate.Header>
          <div className='flex w-full flex-col gap-2'>
            <FormField
              control={form.control}
              name='fullName'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <T keyName='auth.steps.orgForm.fullName.label' />
                    <FormLabelAsterisk />
                  </FormLabel>
                  <FormControl>
                    <Input
                      autoFocus
                      placeholder={t('auth.steps.orgForm.fullName.placeholder')}
                      maxLength={MAX_ORG_CHARS}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='orgName'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <T keyName='auth.steps.orgForm.orgName.label' />
                    <FormLabelAsterisk />
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t('auth.steps.orgForm.orgName.placeholder')}
                      maxLength={MAX_ORG_CHARS}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='role'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <T keyName='auth.steps.orgForm.role.label' />
                    <FormLabelAsterisk />
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t('auth.steps.orgForm.role.placeholder')}
                      maxLength={MAX_ORG_CHARS}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='orgSize'
              render={({ field: { onChange, ref, ...rest } }) => (
                <FormItem>
                  <FormLabel>
                    <T keyName='auth.steps.orgForm.orgSize.label' />
                    <FormLabelAsterisk />
                  </FormLabel>
                  <FormControl>
                    <Select onValueChange={onChange} {...rest}>
                      <SelectTrigger>
                        <SelectValue placeholder={t('auth.steps.orgForm.orgSize.placeholder')} />
                      </SelectTrigger>
                      <SelectContent>
                        {ORG_SIZE_KEYS.map((key) => (
                          <SelectItemFromKey key={key} translationKey={key} />
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='industry'
              render={({ field: { onChange, ref, ...rest } }) => (
                <FormItem>
                  <FormLabel>
                    <T keyName='auth.steps.orgForm.industry.label' />
                    <FormLabelAsterisk />
                  </FormLabel>
                  <FormControl>
                    <Select
                      onValueChange={(value) => {
                        onChange(value)
                        setShowOther(isOtherSelected(value))
                      }}
                      {...rest}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder={t('auth.steps.orgForm.industry.placeholder')} />
                      </SelectTrigger>
                      <SelectContent>
                        {INDUSTRY_OPTION_KEYS.map((key) => (
                          <SelectItemFromKey key={key} translationKey={key} />
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {showOther && (
              <FormField
                control={form.control}
                name='otherIndustry'
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder={t('auth.steps.orgForm.industry.other.placeholder')}
                        maxLength={MAX_ORG_CHARS}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            <AuthStepTemplate.ButtonRow className='pt-6'>
              <Button variant='square-outline' size='sm' className='w-full' onClick={back}>
                <T keyName='auth.steps.common.buttons.back' />
              </Button>
              <Button
                type='submit'
                variant='square'
                size='sm'
                disabled={isSubmitDisabled}
                className='w-full'
              >
                <T keyName='auth.steps.common.buttons.next' />
              </Button>
            </AuthStepTemplate.ButtonRow>
          </div>
        </form>
      </Form>
      <AuthStepTemplate.Footer />
    </AuthStepTemplate>
  )
}

// 👇 select option keys

const ORG_SIZE_KEYS: TranslationKey[] = [
  'auth.steps.orgForm.orgSize.options.0',
  'auth.steps.orgForm.orgSize.options.1',
  'auth.steps.orgForm.orgSize.options.2',
  'auth.steps.orgForm.orgSize.options.3',
  'auth.steps.orgForm.orgSize.options.4',
]
const OTHER_INDUSTRY_OPTION: TranslationKey = 'auth.steps.orgForm.industry.options.11'
const INDUSTRY_OPTION_KEYS: TranslationKey[] = [
  'auth.steps.orgForm.industry.options.0',
  'auth.steps.orgForm.industry.options.1',
  'auth.steps.orgForm.industry.options.2',
  'auth.steps.orgForm.industry.options.3',
  'auth.steps.orgForm.industry.options.4',
  'auth.steps.orgForm.industry.options.5',
  'auth.steps.orgForm.industry.options.6',
  'auth.steps.orgForm.industry.options.7',
  'auth.steps.orgForm.industry.options.8',
  'auth.steps.orgForm.industry.options.9',
  'auth.steps.orgForm.industry.options.10',
  OTHER_INDUSTRY_OPTION,
]
