import { cn } from '@/lib'
import { cva } from 'class-variance-authority'

const paddingXClasses = cva('px-8 h-md:xl:px-20 h-md:2xl:px-28')

type PageTemplateProps = ComponentProps<{
  title?: React.ReactNode
  subtitle?: React.ReactNode
  actionButton?: React.ReactNode
  icon?: React.ReactNode
  noPadding?: boolean
}>

export const PageTemplate = ({
  children,
  className,
  title,
  subtitle,
  actionButton,
  icon,
  noPadding,
}: PageTemplateProps) => {
  return (
    <div className='flex w-full flex-col overflow-y-auto bg-content pl-[88px]'>
      {title && (
        <header
          className={paddingXClasses({
            className:
              'flex flex-col justify-between gap-3 border-b border-b-grey-100 py-4 md:flex-row md:items-center',
          })}
        >
          <span className='flex items-center gap-2'>
            <h1 className='pt-[5px] font-neue-machina text-lg font-normal uppercase leading-8 tracking-wide lg:text-xl'>
              {title}
            </h1>
            {icon}
          </span>
          {subtitle && (
            <span className='flex flex-wrap items-center justify-between gap-4 lg:gap-8'>
              <h2 className='pt-[5px] font-neue-machina font-normal leading-8 tracking-wide lg:text-xl'>
                {subtitle}
              </h2>
              {actionButton}
            </span>
          )}
        </header>
      )}
      <section
        className={cn(
          !noPadding &&
            paddingXClasses({
              className: 'm-md:lg:pt-20 pb-4 pt-8 xl:pb-10 h-md:xl:pt-28',
            }),
          'flex flex-col gap-10',
          className,
        )}
      >
        {children}
      </section>
    </div>
  )
}

PageTemplate.H1 = function PageTemplateH1({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h1
      className={cn('font-neue-machina text-[32px] font-bold leading-10 tracking-wide', className)}
      {...props}
    >
      {children}
    </h1>
  )
}

PageTemplate.H2 = function PageTemplateH2({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h2 className={cn('font-neue-machina text-2xl font-bold tracking-wide', className)} {...props}>
      {children}
    </h2>
  )
}
