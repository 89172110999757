import { useOnAuthStateChanged } from '@/app/_auth'
import { ConsentFormSchema, OrgFormSchema, PurposeFormSchema } from '@/app/_auth/steps'
import React from 'react'

export type RegistrationFormContextValue = {
  orgData: OrgFormSchema | null
  setOrgData: SetState<OrgFormSchema | null>
  purposeData: PurposeFormSchema | null
  setPurposeData: SetState<PurposeFormSchema | null>
  consentData: ConsentFormSchema | null
  setConsentData: SetState<ConsentFormSchema | null>
}

const RegistrationFormContext = React.createContext<RegistrationFormContextValue>({
  orgData: null,
  setOrgData: () => {
    throw new Error('RegistrationFormContext.setOrgData default value used')
  },
  purposeData: null,
  setPurposeData: () => {
    throw new Error('RegistrationFormContext.setPurposeData default value used')
  },
  consentData: null,
  setConsentData: () => {
    throw new Error('RegistrationFormContext.setConsentData default value used')
  },
})

export const RegistrationFormContextProvider = ({
  value,
  children,
}: React.PropsWithChildren<{ value: RegistrationFormContextValue }>) => (
  <RegistrationFormContext.Provider value={value}>{children}</RegistrationFormContext.Provider>
)

export function RegistrationFormProvider({ children }: React.PropsWithChildren) {
  const [orgData, setOrgData] = React.useState<OrgFormSchema | null>(null)
  const [purposeData, setPurposeData] = React.useState<PurposeFormSchema | null>(null)
  const [consentData, setConsentData] = React.useState<ConsentFormSchema | null>(null)

  const value = React.useMemo(
    () => ({
      orgData,
      setOrgData,
      purposeData,
      setPurposeData,
      consentData,
      setConsentData,
    }),
    [orgData, purposeData, consentData],
  )

  useOnAuthStateChanged({
    onLogin: () => {
      setConsentData(null)
      setOrgData(null)
      setPurposeData(null)
    },
  })

  return <RegistrationFormContextProvider value={value}>{children}</RegistrationFormContextProvider>
}

export function useRegistrationForm() {
  const context = React.useContext(RegistrationFormContext)

  if (!context) {
    throw new Error('useRegistrationForm must be used within RegistrationFormContext.Provider')
  }

  return context
}
