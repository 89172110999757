import { auth } from '@/app/_auth/auth'
import {
  AUTH_PROVIDER_DATA,
  type AuthProviderID,
  CreateAccountStep,
  DeactivatedStep,
  EmergenceUser,
  GetEmergenceUserStep,
  LinkProviderStep,
  LoginStep,
  RegisterConsentStep,
  RegisterOrgStep,
  RegisterPurposeStep,
  RegistrationFormProvider,
} from '@/app/_auth/steps'
import type { FirebaseError } from 'firebase/app'
import { type User, onAuthStateChanged, signOut } from 'firebase/auth'
import React from 'react'

// 👇 step data

export enum AuthStep {
  Create = 'create',
  Login = 'login',
  LinkProvider = 'link-provider',
  Deactivated = 'deactivated',
  GetEmergenceUser = 'get-emergence-user',
  RegisterOrg = 'register-org',
  RegisterPurpose = 'register-purpose',
  RegisterConsent = 'register-consent',
}

const AUTH_STEP_COMPONENT: Record<AuthStep, React.FC> = {
  [AuthStep.Login]: LoginStep,
  [AuthStep.LinkProvider]: LinkProviderStep,
  [AuthStep.Create]: CreateAccountStep,
  [AuthStep.GetEmergenceUser]: GetEmergenceUserStep,
  [AuthStep.Deactivated]: DeactivatedStep,
  [AuthStep.RegisterOrg]: RegisterOrgStep,
  [AuthStep.RegisterPurpose]: RegisterPurposeStep,
  [AuthStep.RegisterConsent]: RegisterConsentStep,
}

// 👇 components

export function AuthStepNavigator({ step }: Readonly<{ step: AuthStep }>) {
  const AuthStepComponent = AUTH_STEP_COMPONENT[step]
  return (
    <RegistrationFormProvider>
      <AuthStepComponent />
    </RegistrationFormProvider>
  )
}

export const AuthContextProvider = ({
  children,
  value,
}: React.PropsWithChildren<{ value: AuthContextValue }>) => (
  <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
)

export const AuthGuard = ({
  step,
  children,
}: React.PropsWithChildren<{ step: AuthStep | null }>) =>
  step === null ? children : <AuthStepNavigator step={step} />

// 👇 provider

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const [step, setStep] = React.useState<AuthContextValue['step']>(AuthStep.GetEmergenceUser)
  const [user, setUser] = React.useState<AuthContextValue['user']>(null)
  const [authError, setAuthError] = React.useState<AuthContextValue['authError']>(null)

  const value = React.useMemo(
    () => ({ step, setStep, user, setUser, authError, setAuthError }),
    [step, user, authError],
  )

  useOnAuthStateChanged({
    onLogin: () => setStep(AuthStep.GetEmergenceUser),
    onLogout: () => {
      setUser(null)

      if (step !== AuthStep.Create && step !== AuthStep.Login) {
        setStep(AuthStep.Login)
      }
    },
  })

  return (
    <AuthContextProvider value={value}>
      {import.meta.env.VITE_DEV_MODE && <AuthStepDevTools />}
      <AuthGuard step={step}>{children}</AuthGuard>
    </AuthContextProvider>
  )
}

// 👇 auth context

interface AuthError extends FirebaseError {
  customData?: FirebaseError['customData'] & {
    email?: string
    _tokenResponse?: { providerId?: AuthProviderID; verifiedProvider?: AuthProviderID[] }
  }
}

export type AuthContextValue = {
  step: AuthStep | null
  setStep: SetState<AuthStep | null>
  user: EmergenceUser | null
  setUser: SetState<EmergenceUser | null>
  authError: AuthError | null
  setAuthError: SetState<AuthError | null>
}

const AuthContext = React.createContext<AuthContextValue>({
  user: null,
  step: AuthStep.GetEmergenceUser,
  setStep: () => {
    throw new Error('AuthContext.setStep default value used.')
  },
  setUser: () => {
    throw new Error('AuthContext.setUser default value used.')
  },
  authError: null,
  setAuthError: () => {
    throw new Error('AuthContext.setAuthError default value used.')
  },
})

// 👇 hooks

export function useAuth() {
  const authContext = React.useContext(AuthContext)

  if (!authContext) throw new Error('useAuth must be used within AuthContext.Provider')

  return authContext
}

export function useLogoutOnMount() {
  React.useEffect(() => {
    if (auth.currentUser) signOut(auth)
  }, [])
}

export function useOnAuthStateChanged({
  onLogin,
  onLogout,
}: {
  onLogin?: (user: User) => void
  onLogout?: () => void
}) {
  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) onLogin && onLogin(user)
      else onLogout && onLogout()
    })

    return unsubscribe
  }, [])
}

export function useAuthError(): {
  providerName: string
  verifiedProvider: AuthProviderID[]
  email: string
} {
  const { authError } = useAuth()

  if (!authError?.customData) throw new Error('AUTH_ERROR_DATA_NOT_FOUND')

  const { email, _tokenResponse } = authError.customData

  if (!email) throw new Error('AUTH_ERROR_EMAIL_NOT_FOUND')
  if (!_tokenResponse) throw new Error('AUTH_ERROR_TOKEN_RESPONSE_NOT_FOUND')

  const { providerId, verifiedProvider = [] } = _tokenResponse

  if (!providerId) throw new Error('AUTH_ERROR_PROVIDER_ID_NOT_FOUND')
  if (verifiedProvider.length === 0) throw new Error('AUTH_ERROR_VERIFIED_PROVIDER_NOT_FOUND')

  return { providerName: AUTH_PROVIDER_DATA[providerId].displayName, verifiedProvider, email }
}

// 👇 dev tools

const AuthStepDevTools = () => {
  const authSteps = Object.entries(AuthStep)
  const { setStep, step } = useAuth()

  React.useEffect(() => {
    console.log(
      `%cAUTH_STEP: ${step}`,
      'background:gold;color:black;padding:4px 6px;border-radius:3px;',
    )
  }, [step])

  if (step === null) return

  return (
    <div className=' absolute left-0 flex flex-col gap-3 bg-red-600 p-7'>
      <button onClick={() => setStep(null)} className='border border-black p-3'>
        BypassAuth
      </button>
      <button onClick={() => signOut(auth)} className='border border-black p-3'>
        SignOut
      </button>
      {authSteps.map(([step, key]) => (
        <button
          key={key}
          onClick={() => setStep(key as AuthStep)}
          className='border border-black p-3'
        >
          {step}
        </button>
      ))}
    </div>
  )
}
