import * as TogglePrimitive from '@radix-ui/react-toggle'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

const toggleVariants = cva(
  'flex items-center h-12 w-full bg-button-accent-bg border border-button-accent-border outline-none justify-center rounded font-light hover:bg-button-accent-gray-hover focus-visible:bg-button-accent-gray-hover outline-0 focus-visible:outline-2 focus:outline-2 outline-button-accent-outline -outline-offset-1 disabled:pointer-events-none disabled:opacity-50 data-[state=on]:bg-button-accent-gray-selected',
)

const Toggle = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> & VariantProps<typeof toggleVariants>
>(({ className, ...props }, ref) => (
  <TogglePrimitive.Root ref={ref} className={toggleVariants({ className })} {...props} />
))

Toggle.displayName = TogglePrimitive.Root.displayName

export { Toggle, toggleVariants }
