import { cn } from '@/lib/utils'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import * as React from 'react'
import { FormLabel } from './form'

export const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    tabIndex={0}
    className={cn(
      'peer relative h-3 w-3 shrink-0 focus:outline focus:outline-[2px] focus:outline-grey-100 disabled:cursor-not-allowed disabled:opacity-50',
      className,
    )}
    {...props}
  >
    <UncheckedIcon />
    <CheckboxPrimitive.Indicator
      className={cn('absolute top-0 flex items-center justify-center text-current')}
    >
      <CheckedIcon />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export const CheckboxLabel = ({ children }: React.PropsWithChildren) => (
  <FormLabel unstyled className='mt-0 items-start font-circular-xx text-xl font-light'>
    {children}
  </FormLabel>
)

const UncheckedIcon = () => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='0.5' width='11' height='11' stroke='#AEA9B4' />
  </svg>
)
const CheckedIcon = () => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='0.5' width='11' height='11' stroke='#AEA9B4' />
    <rect x='2' y='2' width='8' height='8' fill='#786E96' />
  </svg>
)
