import { AuthStep, useAuth } from '@/app/_auth'
import {
  AuthStepTemplate,
  MAX_PURPOSE_CHARS,
  purposeFormSchema,
  PurposeFormSchema,
  useRegistrationForm,
} from '@/app/_auth/steps'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormFieldCharacterCount,
  FormItem,
  Textarea,
} from '@/components/ui'
import { T, useTranslate } from '@/i18n'
import { GA_EVENTS } from '@/lib'
import { zodResolver } from '@hookform/resolvers/zod'
import ReactGA from 'react-ga4'
import { useForm } from 'react-hook-form'

export function RegisterPurposeStep() {
  const { t } = useTranslate()
  const { setPurposeData, purposeData } = useRegistrationForm()
  const { setStep } = useAuth()

  const form = useForm<PurposeFormSchema>({
    resolver: zodResolver(purposeFormSchema),
    ...(purposeData && { values: purposeData }),
    defaultValues: { purpose: '' },
  })

  const submit = (data: PurposeFormSchema) => {
    ReactGA.event({
      action: GA_EVENTS.ACTION.BTN,
      category: GA_EVENTS.CATEGORY.ACC,
      label: 'submit_purpose_registration_form',
    })

    setPurposeData(data)
    setStep(AuthStep.RegisterConsent)
  }

  const back = () => {
    setPurposeData(form.getValues())
    setStep(AuthStep.RegisterOrg)
  }

  return (
    <AuthStepTemplate>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(submit)} className='flex flex-col gap-8'>
          <AuthStepTemplate.Header>
            <AuthStepTemplate.H2 keyName='auth.steps.usage.title' />
          </AuthStepTemplate.Header>
          <div className='w-full'>
            <FormField
              control={form.control}
              name='purpose'
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Textarea
                      autoFocus
                      className='min-h-24'
                      placeholder={t('auth.steps.usage.form.purpose.placeholder')}
                      maxLength={MAX_PURPOSE_CHARS}
                      {...field}
                    />
                  </FormControl>
                  <FormFieldCharacterCount watch={form.watch('purpose')} max={MAX_PURPOSE_CHARS} />
                </FormItem>
              )}
            />
            <AuthStepTemplate.ButtonRow className='pt-5'>
              <Button onClick={back} variant='square-outline' className='w-full' size='sm'>
                <T keyName='auth.steps.common.buttons.back' />
              </Button>
              <Button
                type='submit'
                disabled={!form.formState.isValid}
                variant='square'
                size='sm'
                className='w-full'
              >
                <T keyName='auth.steps.common.buttons.next' />
              </Button>
            </AuthStepTemplate.ButtonRow>
          </div>
        </form>
      </Form>
      <AuthStepTemplate.Footer />
    </AuthStepTemplate>
  )
}
