import { FormatIcu } from '@tolgee/format-icu'
import { DevTools, LanguageDetector, Tolgee, TolgeeOptions, TolgeeProvider } from '@tolgee/react'
import { DEFAULT_LOCALE, Locale, SUPPORTED_LOCALES } from './config'

export interface I18nBaseConfigProps extends TolgeeOptions {}

export const I18nBaseConfig = (options: I18nBaseConfigProps) => {
  return Tolgee()
    .use(DevTools())
    .use(FormatIcu())
    .use(LanguageDetector())
    .init({
      availableLanguages: SUPPORTED_LOCALES,
      defaultLanguage: DEFAULT_LOCALE,
      fallbackLanguage: DEFAULT_LOCALE,
      ...options,
    })
}

const tolgee = I18nBaseConfig({
  staticData: {
    [Locale.enUS]: () => import('@/i18n/messages/en-US.json'),
  },
})

export const I18nProvider = ({ children }: React.PropsWithChildren) => (
  <TolgeeProvider tolgee={tolgee}>{children}</TolgeeProvider>
)
