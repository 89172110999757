import { cn } from '@/lib'

const EmergenceLogo = ({ className, ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={cn('pt-1.5', className)}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 206 26'
    fill='none'
    {...props}
  >
    <path
      d='M0 9.40426C0 3.50355 3.921 0 9.46958 0C15.0182 0 18.8282 3.39291 18.8282 9.03546V10.5844H4.51285C4.58683 13.2766 6.17742 15.3787 10.0244 15.3787C12.5028 15.3787 14.2414 14.3461 14.7222 12.3915H19.494C18.9761 15.1206 16.2388 19.2511 10.0244 19.2511C3.10721 19.2511 0 14.678 0 9.40426ZM4.51285 7.7078H14.3153C14.3153 5.67943 12.9097 3.90922 9.46958 3.90922C6.06645 3.90922 4.51285 5.67943 4.51285 7.7078Z'
      fill='currentColor'
    ></path>
    <path
      d='M46.7838 0C51.1857 0 52.7762 3.79858 52.7762 8.44539V18.8823H48.0045V8.96171C48.0045 5.42128 47.3386 4.1305 44.8233 4.1305C41.4571 4.1305 40.1255 7.11773 40.1255 11.7277V18.8823H35.3537V8.96171C35.3537 5.42128 34.6879 4.1305 32.1725 4.1305C28.8064 4.1305 27.4747 7.11773 27.4747 11.7277V18.8823H22.7029V0.368794H27.4747V2.76596L26.6239 5.56879H27.8446C28.5844 2.24964 30.36 0 34.133 0C37.5731 0 39.1267 2.3234 39.6446 5.56879H40.5324C41.2722 2.24964 43.0107 0 46.7838 0Z'
      fill='currentColor'
    ></path>
    <path
      d='M55.7387 9.40426C55.7387 3.50355 59.6597 0 65.2082 0C70.7568 0 74.5668 3.39291 74.5668 9.03546V10.5844H60.2515C60.3255 13.2766 61.9161 15.3787 65.7631 15.3787C68.2415 15.3787 69.98 14.3461 70.4609 12.3915H75.2327C74.7148 15.1206 71.9775 19.2511 65.7631 19.2511C58.8459 19.2511 55.7387 14.678 55.7387 9.40426ZM60.2515 7.7078H70.054C70.054 5.67943 68.6484 3.90922 65.2082 3.90922C61.8051 3.90922 60.2515 5.67943 60.2515 7.7078Z'
      fill='currentColor'
    ></path>
    <path
      d='M78.4416 18.8823V2.91348C78.4416 1.03263 79.4773 5.45074e-06 81.3639 0.0737634L92.7939 0.368794V4.4993L83.2134 4.24113V18.8823H78.4416Z'
      fill='currentColor'
    ></path>
    <path
      d='M116.245 0.368794H117.873V4.4993H116.504C115.764 4.4993 115.099 4.60993 114.507 5.0156C114.951 5.56879 115.321 6.60142 115.321 7.81844C115.321 11.5064 112.065 13.7929 106.813 15.6369C111.067 15.2681 115.099 16.1532 115.099 20.1362C115.099 25.3731 109.217 26 95.9376 26V21.8695C107.442 21.8695 110.327 21.6482 110.327 19.2879C110.327 16.0426 103.04 17.5546 97.7871 18.661L97.1582 15.8213C100.894 15.2312 104.371 14.6411 107.553 13.7929V12.5759C101.079 14.6411 94.6799 13.2766 94.6799 7.33901C94.6799 2.36029 99.1557 0 104.778 0C107.368 0 109.846 0.553196 111.733 1.91774C113.175 0.553196 114.581 0.368794 116.245 0.368794ZM105.037 11.027C108.699 11.027 110.549 9.95745 110.549 7.59716C110.549 5.23688 108.699 4.1305 105.037 4.1305C101.338 4.1305 99.4517 5.23688 99.4517 7.59716C99.4517 9.95745 101.338 11.027 105.037 11.027Z'
      fill='currentColor'
    ></path>
    <path
      d='M119.425 9.40426C119.425 3.50355 123.346 0 128.894 0C134.443 0 138.253 3.39291 138.253 9.03546V10.5844H123.937C124.011 13.2766 125.602 15.3787 129.449 15.3787C131.927 15.3787 133.666 14.3461 134.147 12.3915H138.919C138.401 15.1206 135.663 19.2511 129.449 19.2511C122.532 19.2511 119.425 14.678 119.425 9.40426ZM123.937 7.7078H133.74C133.74 5.67943 132.334 3.90922 128.894 3.90922C125.491 3.90922 123.937 5.67943 123.937 7.7078Z'
      fill='currentColor'
    ></path>
    <path
      d='M152.744 4.1305C149.267 4.1305 146.899 6.38015 146.899 11.5064V18.8823H142.127V0.368794H146.899V2.98723L146.048 5.82695H147.269C148.009 2.5078 150.339 0 154.334 0C158.847 0 161.658 2.8766 161.658 8.37164V18.8823H156.924V9.07235C156.924 5.49504 155.703 4.1305 152.744 4.1305Z'
      fill='currentColor'
    ></path>
    <path
      d='M164.687 9.62553C164.687 3.79858 168.608 0 174.564 0C179.077 0 183.663 2.47092 184.033 7.78156H179.262C178.892 5.3844 177.079 4.1305 174.564 4.1305C171.161 4.1305 169.459 6.41702 169.459 9.62553C169.459 12.8709 171.161 15.1206 174.564 15.1206C177.079 15.1206 178.892 13.8667 179.262 11.4695H184.033C183.663 16.7801 179.077 19.2511 174.564 19.2511C168.608 19.2511 164.687 15.4894 164.687 9.62553Z'
      fill='currentColor'
    ></path>
    <path
      d='M186.506 9.40426C186.506 3.50355 190.427 0 195.976 0C201.524 0 205.334 3.39291 205.334 9.03546V10.5844H191.019C191.093 13.2766 192.683 15.3787 196.53 15.3787C199.009 15.3787 200.747 14.3461 201.228 12.3915H206C205.482 15.1206 202.745 19.2511 196.53 19.2511C189.613 19.2511 186.506 14.678 186.506 9.40426ZM191.019 7.7078H200.821C200.821 5.67943 199.416 3.90922 195.976 3.90922C192.572 3.90922 191.019 5.67943 191.019 7.7078Z'
      fill='currentColor'
    ></path>
  </svg>
)

export { EmergenceLogo }
