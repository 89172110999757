import { cn } from '@/lib/utils'
import { IconProps } from '@/components/icons/types'

const AlertIcon = ({ color = '#D56262', className, ...props }: IconProps) => (
  <svg
    width='52'
    height='48'
    viewBox='0 0 52 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={cn(className)}
    {...props}
  >
    <path
      d='M31.3846 4.18404L50.1508 37.4148C52.5731 41.7043 49.5233 47 44.7661 47H7.23386C2.47672 47 -0.573133 41.7043 1.84924 37.4148L20.6154 4.18403C23.0128 -0.0613479 28.9872 -0.0613441 31.3846 4.18404Z'
      fill='#F8E5E5'
      stroke={color}
      strokeWidth='2'
    />
    <rect width='4' height='19' transform='translate(24 19)' fill='#F8E5E5' />
    <path d='M28 19L27.2632 31H24.7368L24 19H28Z' fill={color} />
    <circle cx='26' cy='36' r='2' fill={color} />
  </svg>
)

export { AlertIcon }
