import { cn } from '@/lib/utils'
import { Root as SwitchPrimitive, Thumb as ThumbPrimitive } from '@radix-ui/react-switch'
import { ComponentProps, ElementRef, forwardRef } from 'react'

export type SwitchProps = ComponentProps<typeof SwitchPrimitive> & {
  /**
   * The aria-label attribute defines a string value that labels an
   * interactive element. This provides the element with its accessible name.
   */
  'aria-label': string
  /**
   * Classnames provided to the Switch element.
   */
  className?: string
}

export const Switch = forwardRef<ElementRef<typeof SwitchPrimitive>, SwitchProps>(
  ({ className, ...props }, forwardedRef) => (
    <SwitchPrimitive
      {...props}
      tabIndex={0}
      className={cn(
        'relative h-[24px] w-10 cursor-pointer rounded-full bg-grey-100 transition-all duration-100 ease-in data-[state=checked]:bg-grey-600',
        'focus:shadow-input-focus focus:outline-0 data-[state=checked]:focus:shadow-checked-switch-focus',
        className,
      )}
      ref={forwardedRef}
    >
      <ThumbPrimitive
        className={cn(
          'transition-border ml-0.5 box-border block h-[18px] w-[18px] rounded-full border-2 border-white bg-white transition-transform duration-200 ease-in will-change-transform data-[state=checked]:translate-x-[18px]',
        )}
      />
    </SwitchPrimitive>
  ),
)

Switch.displayName = SwitchPrimitive.displayName
