import { TranslationKey } from '@/i18n'
import React from 'react'

// 👇 login button

export const LoginButton = (
  props: Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'>,
) => (
  <button
    tabIndex={0}
    className='flex h-10 items-center justify-center gap-[10px] rounded border border-[#747775] bg-white px-2 font-medium text-[#1F1F1F] outline-[#a28fda] hover:bg-gray-50'
    {...props}
  />
)

// 👇 provider icons

const GoogleIcon = () => (
  <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask
      id='mask0_2927_208597'
      style={{ maskType: 'luminance' }}
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='21'
      height='21'
    >
      <path d='M20.5 0.192871H0.5V20.1929H20.5V0.192871Z' fill='white' />
    </mask>
    <g mask='url(#mask0_2927_208597)'>
      <path
        d='M20.1 10.42C20.1 9.71091 20.0364 9.02911 19.9182 8.37451H10.5V12.2427H15.8818C15.65 13.4927 14.9455 14.5518 13.8864 15.2609V17.77H17.1182C19.0091 16.0291 20.1 13.4654 20.1 10.42Z'
        fill='#4285F4'
      />
      <path
        d='M10.5009 20.1928C13.2009 20.1928 15.4645 19.2973 17.119 17.7701L13.8872 15.261C12.9918 15.861 11.8463 16.2155 10.5009 16.2155C7.89625 16.2155 5.69175 14.4564 4.90535 12.0928H1.56445V14.6837C3.20995 17.9519 6.59175 20.1928 10.5009 20.1928Z'
        fill='#34A853'
      />
      <path
        d='M4.9045 12.093C4.7045 11.493 4.5909 10.8521 4.5909 10.193C4.5909 9.53395 4.7045 8.89305 4.9045 8.29305V5.70215H1.5636C0.8864 7.05215 0.5 8.57945 0.5 10.193C0.5 11.8066 0.8864 13.3339 1.5636 14.6839L4.9045 12.093Z'
        fill='#FBBC04'
      />
      <path
        d='M10.5009 4.17017C11.969 4.17017 13.2872 4.67467 14.3236 5.66557L17.1918 2.79737C15.46 1.18377 13.1963 0.192871 10.5009 0.192871C6.59175 0.192871 3.20995 2.43377 1.56445 5.70197L4.90535 8.29287C5.69175 5.92927 7.89625 4.17017 10.5009 4.17017Z'
        fill='#E94235'
      />
    </g>
  </svg>
)

const MicrosoftIcon = () => (
  <svg width='21' height='22' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_2927_208607)'>
      <path d='M9.98042 10.6714H0V0.690918H9.98042V10.6714Z' fill='#F1511B' />
      <path d='M21 10.6714H11.0195V0.690918H21V10.6714Z' fill='#80CC28' />
      <path d='M9.98003 21.6953H0V11.7148H9.98003V21.6953Z' fill='#00ADEF' />
      <path d='M21 21.6948H11.0195V11.7144H21V21.6948Z' fill='#FBBC09' />
    </g>
    <defs>
      <clipPath id='clip0_2927_208607'>
        <rect width='21.0002' height='21.0042' fill='white' transform='translate(0 0.690918)' />
      </clipPath>
    </defs>
  </svg>
)

// 👇 provider data

export enum AuthProviderID {
  Google = 'google.com',
  Microsoft = 'microsoft.com',
}

export const AUTH_PROVIDER_DATA: Record<
  AuthProviderID,
  {
    icon: React.ReactNode
    translationKeys: { title: TranslationKey; a11y: TranslationKey }
    displayName: string
  }
> = {
  [AuthProviderID.Google]: {
    icon: <GoogleIcon />,
    translationKeys: { title: 'auth.googleButton.title', a11y: 'auth.googleButton.a11y' },
    displayName: 'Google',
  },
  [AuthProviderID.Microsoft]: {
    icon: <MicrosoftIcon />,
    translationKeys: { title: 'auth.microsoftButton.title', a11y: 'auth.microsoftButton.a11y' },
    displayName: 'Microsoft',
  },
} as const
