import React from 'react'

export enum AppProduct {
  Easy = 'easy',
  RaAgent = 'ra-agent',
}

export const DEFAULT_APP_PRODUCT: AppProduct = AppProduct.Easy

export const AppProductProvider = ({ children }: React.PropsWithChildren) => {
  const [appProduct, setAppProduct] = React.useState<AppProduct>(DEFAULT_APP_PRODUCT)
  const value = React.useMemo(() => ({ appProduct, setAppProduct }), [appProduct])

  return <AppProductContext.Provider value={value}>{children}</AppProductContext.Provider>
}

const AppProductContext = React.createContext<{
  appProduct: AppProduct
  setAppProduct: SetState<AppProduct>
}>({
  appProduct: DEFAULT_APP_PRODUCT,
  setAppProduct: () => {
    throw new Error('AppProductContext.setAppProduct default value used.')
  },
})

export function useAppProduct() {
  const context = React.useContext(AppProductContext)

  if (!context) throw new Error('useAppProduct must be used within AppProductContext.Provider')

  return context
}
