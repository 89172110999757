const BASE_API_PATH = '/api/v0/users'

type BaseAPIPath = typeof BASE_API_PATH

export const API_PATHS = {
  USERS: BASE_API_PATH,
  RUNS: `${BASE_API_PATH}/requestRuns`,
  DEACTIVATE: `${BASE_API_PATH}/deactivateAccount`,
  REACTIVATE: `${BASE_API_PATH}/reactivateAccount`,
  REGISTER: `${BASE_API_PATH}/register`,
  CONTACT: `${BASE_API_PATH}/contact`,
  PREFERENCES: `${BASE_API_PATH}/privacyPreferences`,
  KEY: `${BASE_API_PATH}/getApiKey`,
} satisfies Record<string, BaseAPIPath | `${BaseAPIPath}/${string}`>
