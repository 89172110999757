import { auth, AuthStep, useAuth } from '@/app/_auth'
import { AuthStepTemplate } from '@/app/_auth/steps'
import { Button, toast } from '@/components/ui'
import { T, useTranslate } from '@/i18n'
import { API_PATHS, GA_EVENTS, handleRequest } from '@/lib'
import { useMutation } from '@tanstack/react-query'
import { signOut } from 'firebase/auth'
import ReactGA from 'react-ga4'

export function DeactivatedStep() {
  const { t } = useTranslate()
  const { isPending, mutateAsync } = useReactivateAccount()
  const { user, setStep } = useAuth()

  const deactivatedDate = user?.lastUpdatedAt
    ? new Date(parseInt(user.lastUpdatedAt + '000'))
    : new Date()

  const reactivate = () => {
    ReactGA.event({
      action: GA_EVENTS.ACTION.BTN,
      category: GA_EVENTS.CATEGORY.ACC,
      label: 'reactivate_account',
    })

    mutateAsync()
      .then(() => setStep(AuthStep.GetEmergenceUser))
      .catch((e) => {
        console.error(e)
        toast.error('Failed to reactivate account')
        setStep(AuthStep.Create)
      })
  }

  return (
    <AuthStepTemplate>
      <AuthStepTemplate.Header>
        <AuthStepTemplate.H1
          className='max-w-[360px]'
          keyName='auth.steps.deactivated.title'
          params={{
            date: deactivatedDate.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }),
          }}
        />
        <AuthStepTemplate.H3 keyName='auth.steps.deactivated.p1' />
        <AuthStepTemplate.H3 keyName='auth.steps.deactivated.p2' />
      </AuthStepTemplate.Header>
      <AuthStepTemplate.ButtonRow>
        <Button
          variant='square-outline'
          size='sm'
          title={t('auth.steps.deactivated.signOutButton.a11y')}
          className='w-full'
          onClick={() => signOut(auth)}
          disabled={isPending}
        >
          <T keyName='auth.steps.deactivated.signOutButton.title' />
        </Button>
        <Button
          variant='square'
          size='sm'
          title={t('auth.steps.deactivated.reactivateButton.a11y')}
          className='w-full'
          onClick={reactivate}
          loading={isPending}
        >
          <T keyName='auth.steps.deactivated.reactivateButton.title' />
        </Button>
      </AuthStepTemplate.ButtonRow>
    </AuthStepTemplate>
  )
}

function useReactivateAccount() {
  return useMutation({
    mutationKey: ['reactivate-account'],
    mutationFn: async () => {
      const response = await handleRequest(API_PATHS.REACTIVATE, { method: 'POST' })

      if (response.status !== 200) {
        throw new Error('FAILED_TO_REACTIVATE_ACCOUNT')
      }
    },
  })
}
