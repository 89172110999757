import { cva } from 'class-variance-authority'
import * as React from 'react'
import { useFormField } from './form'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    const { error } = useFormField()
    return (
      <input
        tabIndex={0}
        type={type}
        className={inputVariants({ error: !!error, className })}
        ref={ref}
        {...props}
      />
    )
  },
)
Input.displayName = 'Input'

export const inputVariants = cva(
  'placeholder:text-input-placeholder flex w-full items-center justify-between rounded border bg-input px-3 -outline-offset-1 focus:outline focus:outline-2 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      error: {
        true: 'border-error-accent focus:outline-error-accent',
        false: 'border-input-border focus:outline-focus',
      },
      height: {
        fixed: 'h-10 whitespace-nowrap',
        auto: 'max-h-[400px] min-h-16',
      },
      text: {
        sm: 'text-sm',
        md: 'text-base',
      },
    },
    defaultVariants: {
      height: 'fixed',
      text: 'sm',
      error: false,
    },
  },
)
