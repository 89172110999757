import { auth } from '@/app/_auth'
import type { RegistrationFormSchema } from '@/app/_auth/steps'
import { API_PATHS, handleRequest } from '@/lib'
import { useMutation } from '@tanstack/react-query'

export function useSaveRegistrationForm() {
  return useMutation({
    retry: 3,
    retryDelay: 250,
    mutationKey: ['register-user'],
    mutationFn: async (data: RegistrationFormSchema) => {
      const response = await handleRequest(API_PATHS.REGISTER, {
        method: 'POST',
        body: JSON.stringify(portRegisterRequest(data)),
      })

      if (response.status === 400) {
        throw new Error('INVALID_REGISTRATION_REQUEST_BODY')
      }

      if (response.status !== 200) {
        throw new Error('FAILED_TO_REGISTER_USER')
      }
    },
  })
}

// 👇 data adapters

export type PolicyData = { name: 'ALLOW_DATA' | 'ALLOW_MARKETING_COMMS'; preference: boolean }[]

type RegisterRequestBody = {
  name: string
  email: string
  organization: string
  role: string
  org_size: string
  industry: string
  purpose: string
  policies: PolicyData
}

function portRegisterRequest(data: RegistrationFormSchema): RegisterRequestBody {
  const email = auth.currentUser?.email

  if (!email) throw new Error('USER_EMAIL_NOT_FOUND')

  return {
    email,
    name: data.fullName,
    organization: data.orgName,
    role: data.role,
    org_size: data.orgSize.trim(),
    industry: data.otherIndustry?.trim() || data.industry.trim(),
    purpose: data.purpose,
    policies: [
      { name: 'ALLOW_DATA', preference: data.allowData },
      { name: 'ALLOW_MARKETING_COMMS', preference: data.allowMarketing },
    ],
  }
}
