import { AuthProvider } from '@/app/_auth'
import { AppProductProvider } from '@/app/app-product-context'
import { Toaster, TooltipProvider } from '@/components/ui'
import { I18nProvider } from '@/i18n'
import {
  browserTracingIntegration,
  init as initSentry,
  replayIntegration,
  tanstackRouterBrowserTracingIntegration,
} from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider, createRouter } from '@tanstack/react-router'
import React from 'react'
import ReactGA from 'react-ga4'
// Import the generated route tree
import { routeTree } from '@/routeTree.gen'

// 👇 TanStack Router
const router = createRouter({ routeTree })

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

// 👇 Google Analytics
ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS_ID)

// 👇 Sentry
initSentry({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  enabled: import.meta.env.VITE_SENTRY_ENABLED,
  debug: import.meta.env.VITE_SENTRY_DEBUG,
  integrations: [
    browserTracingIntegration(),
    replayIntegration(),
    tanstackRouterBrowserTracingIntegration(router),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

export function AppRoot() {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: { retry: false, refetchOnWindowFocus: false, refetchOnMount: true },
        },
      }),
  )

  return (
    <QueryClientProvider client={queryClient}>
      <AppProductProvider>
        <I18nProvider>
          <TooltipProvider>
            <AuthProvider>
              <main className='relative flex h-[100dvh] overflow-y-clip font-circular-xx'>
                <RouterProvider router={router} />
              </main>
            </AuthProvider>
            <Toaster />
          </TooltipProvider>
        </I18nProvider>
      </AppProductProvider>
      {import.meta.env.VITE_DEV_MODE && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  )
}
